import Navbar from "../components/Navbar";

const Work = () => {
  return (
    <>
      <Navbar />
    </>
  );
};

export default Work;
