import Navbar from "../components/Navbar";

const Products = () => {
  return (
    <>
      <Navbar />
    </>
  );
};

export default Products;
