import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai"
import { useEffect, useState } from "react";

const Navbar = () => {
  const [ mobileMenuVisible, setMobileMenuVisible ] = useState(false);
  useEffect(()=>{
    if(mobileMenuVisible) document.getElementById("mobile-menu").style.height="300px";
    else document.getElementById("mobile-menu").style.height="0px";
  },[mobileMenuVisible])
  return (
    <div className="fixed top-0 left-0 w-full z-50 bg-white bg-opacity-40 backdrop-blur-md">
      <div className="flex self-center justify-between py-4 md:py-10 px-8 lg:px-20">
        <Link to="/" className="self-center">
          <img
            src="/logo.png"
            alt="logo"
            className="w-36 md:w-48 lg:w-64"
          />
        </Link>
        <div className="hidden md:flex justify-between self-center gap-8 font-monty text-[#2e4260] font-medium text-opacity-60 tracking-wider">
          <div className="hover:text-opacity-80">
            <Link to="/about">ABOUT</Link>
          </div>
          <div className="hover:text-opacity-80">
            <Link to="/work">WORK</Link>
          </div>
          <div className="hover:text-opacity-80">
            <Link to="/products">PRODUCTS</Link>
          </div>
          <div className="hover:text-opacity-80">
            <Link to="/contact">CONTACT</Link>
          </div>
        </div>
        <button className="hidden md:block self-center font-monty bg-[#e0e9ee] text-[#2e4260] font-normal tracking-widest hover:text-opacity-80 py-3 px-4 lg:px-6">
          <Link to="/contact">CONTACT US</Link>
        </button>
        <AiOutlineMenu onClick={()=>setMobileMenuVisible(!mobileMenuVisible)} className="md:hidden text-3xl self-center cursor-pointer hover:opacity-70"/>
      </div>
      <div id="mobile-menu" className="overflow-hidden transition-[height] duration-500" style={{height:0}}>
        <div className="flex flex-col gap-8 font-monty text-[#2e4260] font-medium text-opacity-60 tracking-wider">
          <div className="hover:text-opacity-80 self-center">
            <Link to="/about">ABOUT</Link>
          </div>
          <div className="hover:text-opacity-80 self-center">
            <Link to="/work">WORK</Link>
          </div>
          <div className="hover:text-opacity-80 self-center">
            <Link to="/products">PRODUCTS</Link>
          </div>
          <div className="hover:text-opacity-80 self-center">
            <Link to="/contact">CONTACT</Link>
          </div>
        </div>
        <button className="mx-auto block my-8 font-monty bg-[#e0e9ee] text-[#2e4260] font-normal tracking-widest hover:text-opacity-80 py-3 px-4 lg:px-6">
          <Link to="/contact">CONTACT US</Link>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
