import Navbar from "../components/Navbar";

const Contact = () => {
  return (
    <>
      <Navbar />
    </>
  );
};

export default Contact;
