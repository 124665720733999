import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="pt-20 md:pt-40">
      <Navbar />
      <div className="bg-coverImage">
        <div className="mb-8 mx-8 md:mx-auto overflow-hidden relative md:w-5/6 lg:w-3/4 pt-32 pb-12 flex flex-col bg-white bg-opacity-50">
          <div className="top-20 text-4xl md:text-5xl lg:text-6xl font-semibold font-monty text-[#2e4260] text-center">
            Grow your business
          </div>
          <div className="mt-8 text-xl md:text-2xl font-light font-notoSerif text-[#292727] text-center opacity-60">
            Give your business a boost with the best-in-class and quality piping
            system.
          </div>
          <button className="text-center self-center mt-28 w-fit font-monty bg-[#d3d9ec] hover:bg-[#e0e9ee] text-[#2e4260] font-normal tracking-widest hover:text-opacity-80 py-3 px-6">
            <Link to="/contact">LEARN MORE</Link>
          </button>
        </div>
      </div>
      <div className="flex flex-col md:w-4/5 lg:w-3/5 justify-center mx-8 md:mx-auto">
        <div className="mt-10 font-monty text-center text-[#1a1b1f] text-opacity-60 text-base tracking-tight ">
          WHAT WE BELIEVE IN
        </div>
        <div className="mt-4 font-notoSerif text-center text-[#2e4260] text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
          Maintaining brand value, put our customers first by delivering durable, high-quality products in time.
        </div>
      </div>
      <div className="mt-20 border-t-2 border-gray-200 md:w-5/6 lg:w-3/4 mx-8 md:mx-auto"></div>
      <div className="flex flex-col-reverse lg:flex-row mt-12 mx-auto w-3/4 gap-10 xl:gap-20">
        <div className="lg:w-1/2 xl:w-1/3 self-center text-center lg:text-left">
          <div className="font-monty text-[#1a1b1f] text-opacity-60 text-base">
            ABOUT
          </div>
          <div className="font-notoSerif text-[#4e6fa1] text-4xl mt-4">
            Who we are
          </div>
          <div className="font-monty text-[#003a94] text-lg mt-8 text-opacity-60">
            We are a leading manufacturing brand that deals with all kinds of
            plumbing, agricultural, and garden pipes.
          </div>
          <button className="text-center self-center mt-8 w-fit font-monty bg-[#2e4260] hover:bg-[#e0e9ee] text-white font-light tracking-widest hover:text-[#2e4260] py-3 px-6">
            <Link to="/about">LEARN MORE</Link>
          </button>
        </div>
        <div className="w-2/3 lg:w-1/2 xl:w-2/3 overflow-hidden self-center">
          <img src="./AboutHome.png" alt="" className="h-[90%]" />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row mt-12 mx-auto w-3/4 gap-10 xl:gap-20">
        <div className="w-2/3 lg:w-1/2 xl:w-2/3 overflow-hidden self-center">
          <img src="./WorkHome.png" alt="" className="h-[50%]" />
        </div>
        <div className="lg:w-1/2 xl:w-1/3 self-center lg:self-start text-center lg:text-left">
          <div className="font-monty text-[#1a1b1f] text-opacity-60 text-base">
            WORK
          </div>
          <div className="font-notoSerif text-[#4e6fa1] text-4xl mt-4">
            What we do
          </div>
          <div className="font-monty text-[#003a94] text-lg mt-8 text-opacity-60">
            Leading manufacturers of best-in-class and quality plumbing,
            agricultural, and garden pipes.
          </div>
          <button className="text-center self-center mt-8 w-fit font-monty bg-[#2e4260] hover:bg-[#e0e9ee] text-white font-light tracking-widest hover:text-[#2e4260] py-3 px-6">
            <Link to="/about">LEARN MORE</Link>
          </button>
        </div>
      </div>
      <div className="mt-40"></div>
    </div>
  );
};

export default Home;
